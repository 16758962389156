import "../assets/scss/Main.scss";
import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";

const Main = lazy(() => import("./Main"));

const HomePage = () => {
    return (<Routes>
        <Route path="/" element={<React.Suspense fallback={null}><Main /></React.Suspense>} />
        <Route path="/:payCode" element={<React.Suspense fallback={null}><Main /></React.Suspense>} />
    </Routes>);
};

export default HomePage;